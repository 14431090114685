<template>
    <div class="card bs-4 p-4">
        <h4 class="mb-3 text-primary">Banners</h4>
        <div class="row">
            <div class="col-4">
                <validated-vue-select label="Type" name="Type" v-model="model.type" select-first
                                      :rules="{required : true}" :options="typeOption"
                                      :disabled="loading" @blur="resetModel(model.type)"/>
            </div>
        </div>
        <div class="row" v-if="model.type==='home_page'">
            <div class="col">
                <validated-input label="Banner Name" name="Banner Name" v-model="model.banner_name"
                                 :disabled="loading || loading1 || loading2"/>
            </div>
            <div class="col">
                <validated-input label="Home Page File" name="Home Page" v-model="model.link"
                                 :disabled="loading || loading1 || loading2"/>
            </div>
            <div class="col pt-4">
                <btn size="md" @click="updateClicked('home')" text="Update Home Banner"
                     :disabled="loading || loading1 || loading2" :loading="loading"
                     loading-text="Updating Home Banner..." class="w-15r"/>
                <btn v-if="homeFile" size="md" @click="viewFile('home')" icon="fa fa-eye" class="ml-1"
                     :disabled="loading || loading1 || loading2"/>
            </div>
        </div>
        <div class="row" v-if="model.type==='bulls_page'">
            <div class="col">
                <validated-input label="Banner Name" name="Banner Name" v-model="model.banner_name"
                                 :disabled="loading || loading1 || loading2"/>
            </div>
            <div class="col">
                <validated-file-input ref="file1" class="c-file-input" label="Our Bulls File" name="Our Bulls"
                                      v-model="model.file" :disabled="loading || loading1 || loading2"
                                      :file_name="bullsFile"/>
            </div>
            <div class="col pt-4">
                <btn size="md" @click="updateClicked('bulls')" text="Update Bulls Banner"
                     :disabled="loading || loading1 || loading2" :loading="loading1"
                     loading-text="Updating Bulls Banner..." class="w-15r"/>
                <btn v-if="homeFile" size="md" @click="viewFile('bulls')" icon="fa fa-eye" class="ml-1"
                     :disabled="loading || loading1 || loading2"/>
            </div>
        </div>
        <div class="row" v-if="model.type==='contact_page'">
            <div class="col">
                <validated-input label="Banner Name" name="Banner Name" v-model="model.banner_name"
                                 :disabled="loading || loading1 || loading2"/>
            </div>
            <div class="col">
                <validated-file-input ref="file2" class="c-file-input" label="Contact Us File" name="Contact Us"
                                      v-model="model.file" :disabled="loading || loading1 || loading2"
                                      :file_name="contactFile"/>
            </div>
            <div class="col pt-4">
                <btn size="md" @click="updateClicked('contact')" text="Update Contact Banner"
                     :disabled="loading || loading1 || loading2" :loading="loading2"
                     loading-text="Updating Contact Banner..." class="w-15r"/>
                <btn v-if="homeFile" size="md" @click="viewFile('contact')" icon="fa fa-eye" class="ml-1"
                     :disabled="loading || loading1 || loading2"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'ViewPage',
    data () {
        return {
            loading: false,
            loading1: false,
            loading2: false,
            model: {},
            typeOption: [
                { value: 'home_page', label: 'Home Banner' },
                { value: 'bulls_page', label: 'Bulls Banner' },
                { value: 'contact_page', label: 'Contact Us banner' }
            ],
            addUrl: urls.admin.banner.add,
            editUrl: urls.admin.banner.edit,
            homeFile: '',
            contactFile: '',
            bullsFile: '',
            banner_name1: '',
            banner_name2: '',
            banner_name3: '',
            homeId: '',
            bullsId: '',
            contactId: '',
            action: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        viewFile (item) {
            if (item === 'home') {
                window.open(this.homeFile);
            }
            if (item === 'bulls') {
                window.open('https://kldb.xeoscript.com/upload/' + this.bullsFile);
            }
            if (item === 'contact') {
                window.open('https://kldb.xeoscript.com/upload/' + this.contactFile);
            }
        },
        resetModel (item) {
            this.model = {};
            this.model.type = item;
            if (item === 'home_page') {
                this.model.banner_name = this.banner_name1;
                this.model.link = this.homeFile;
            } else if (item === 'bulls_page') {
                this.model.banner_name = this.banner_name2;
            } else {
                this.model.banner_name = this.banner_name3;
            }
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.get(urls.admin.banner.list);
            const json = response.data;
            if (json) {
                that.details = json.data;
                if (that.details.length !== 0) {
                    that.setId(that.details);
                }
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.DataLoading = false;
        },
        setId (item) {
            for (let i = 0; i <= item.length - 1; i++) {
                if (item[i].type === 'home_page') {
                    this.homeId = item[i].id;
                    this.homeFile = item[i].link;
                    this.banner_name1 = item[i].banner_name;
                    this.model.banner_name = this.banner_name1;
                    this.model.link = this.homeFile;
                }
                if (item[i].type === 'bulls_page') {
                    this.bullsId = item[i].id;
                    this.bullsFile = item[i].file;
                    this.banner_name2 = item[i].banner_name;
                }
                if (item[i].type === 'contact_page') {
                    this.contactId = item[i].id;
                    this.contactFile = item[i].file;
                    this.banner_name3 = item[i].banner_name;
                }
            }
        },
        async updateClicked (item) {
            this.getFormUrl(item);
            const that = this;
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess(item, json);
            } else {
                that.formError(json);
                that.loading = false;
                that.loading1 = false;
                that.loading2 = false;
            }
            that.loading = false;
            that.loading1 = false;
            that.loading2 = false;
        },
        formError () {
            this.$notify('Could not update, Please try again later.', 'Success', {
                type: 'success'
            });
        },
        formSuccess (item, json) {
            this.model.file = '';
            if (item === 'home') {
                this.homeFile = json.data.file;
                this.$notify('Home Banner Updated Successfully', 'Success', {
                    type: 'success'
                });
            } else if (item === 'bulls') {
                this.$refs.file1.clear();
                this.bullsFile = json.data.file;
                this.$notify('Bulls Banner Updated Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$refs.file2.clear();
                this.contactFile = json.data.file;
                this.$notify('Contact Banner Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            this.loadDetails();
        },
        getFormUrl (item) {
            if (item === 'home') {
                this.loading = true;
                if (this.homeId) {
                    this.model.id = this.homeId;
                    this.action = this.editUrl;
                } else {
                    this.action = this.addUrl;
                }
            } else if (item === 'bulls') {
                this.loading1 = true;
                if (this.bullsId) {
                    this.model.id = this.bullsId;
                    this.action = this.editUrl;
                } else {
                    this.action = this.addUrl;
                }
            } else if (item === 'contact') {
                this.loading2 = true;
                if (this.contactId) {
                    this.model.id = this.contactId;
                    this.action = this.editUrl;
                } else {
                    this.action = this.addUrl;
                }
            } else {

            }
        }
    }
};
</script>

<style scoped>

</style>
